@import '@styles/_variables.scss';
.form-dedicatoria {
  margin-top: 35px;
  border-color: #a79669;
  border-width: 1px;
  border-style: solid;
  padding: 25px;
  margin-bottom: 15px;

  & > .incluya {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  & > label {
    margin-bottom: 15px;
    & > input {
      font-size: 15px !important;
      padding-bottom: 5px !important;
    }
  }
  & > .obligatorios {
    font-size: 13px;
    color: rgba(55, 47, 47, 0.5);
  }

  & > .row {
    display: flex;
    flex: 1;
    flex-direction: row;
    & > :first-child {
      margin-right: 15px;
    }

    & > .ab-form-button {
      height: 50px;
      margin-bottom: 0px;
    }
    & > .ab-form-button:last-child {
      background-color: white;
      border-color: #a79669;
      color: #a79669;
      border-width: 1px;
      border-style: solid;
    }
  }
}
