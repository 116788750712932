@import '@styles/_variables.scss';
.ab-input {
  &[type="text"],
  &[type="password"],
  &[type="date"] {
    outline: none;
    font-family: GT Eesti;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    height: 30px;
    border-bottom: 1px solid rgba(32, 32, 32, 0.2);
    background-color: transparent;
    padding-bottom: 22px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #372f2f;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-family: GT Eesti;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
    }

    &.error {
      border-bottom-color: red;

      &::placeholder {
        color: red;
      }

      &:-webkit-input-placeholder {
        color: red;
      }

      &:-ms-input-placeholder {
        color: red;
      }

      &::-ms-input-placeholder {
        color: red;
      }
    }
    &.half {
      width: 50%;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.placeholder-bold {

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-family: GT Eesti;
      font-style: bold;
      font-weight: 700;
      text-decoration: underline;
      font-size: 16px;
      line-height: 16px;
      color: #372f2f;
    }
  }

  &.container {

    position: relative;
    & > span {
      &.error {
        margin-top: 7px;
        color: red;
        font-family: GT Eesti;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &.checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      & > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
          background-color: #a79669;
        }
      }

      & > .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;

        &:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      /* On mouse-over, add a grey background color */
      &:hover > input ~ .checkmark {
        background-color: #ccc;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      & > input:checked ~ .checkmark:after {
        display: block;
      }
    }

    &.radio {
      /* Customize the label (the container) */
      &.container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        & > input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark:after {
            display: block;
          }

          &:checked ~ .checkmark {
            background-color: #e3decf;
          }
        }

        & > .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
          border-radius: 50%;

          &:after {
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #372f2f;
          }
        }
      }

      /* Create a custom radio button */

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #ccc;
      }

      /* When the radio button is checked, add a blue background */

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    & > .password {
      cursor: pointer;
      user-select: none;
      position: absolute;
      right: 10px;
      bottom: 30px;
      & > img{
        user-select: none;
        opacity: 0.78;
      }
    }
  }
}
