@import '@styles/_variables.scss';
.carrousel-half {
  padding: 118px Max(115px, calc((100% - 1440px) / 2 + 115px));
  background-color: #f9f6ef;

  &.experiencias {
    & > .row {
      & > .gallery {
        & > .marker {
          justify-content: center;
        }
      }
    }
  }
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 72px 30px;
  }

  &.rtl {
    & > .row {
      flex-direction: row-reverse;

      @media screen and (max-width: 1000px) {
        flex-direction: column;
      }

      & > .content {
        padding-right: initial;
        padding-left: 139px;

        @media screen and (max-width: 1000px) {
          padding-left: initial;
          margin-bottom: 10px;
        }
      }
    }
  }

  & > h1 {
    font-size: 13px;
  }

  &.white {
    background-color: #fff;

    & > .row {
      & > .content {
        background-color: #fff;
      }
    }
  }

  &.brown {
    background-color: #372f2f;

    & > .row {
      & > .content {
        background-color: #372f2f;

        h2 {
          color: #fff;
        }
      }

      & > .gallery {
        & > .marker {
          & > button {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);

            &.selected {
              border-bottom: 1px solid #a79669;
            }
          }
        }
      }
    }
  }

  &.plus {
    &.rtl {
      & > .row {
        & > .content {
          padding-right: initial;
          padding-left: 38px;
        }
      }
    }

    & > .row {
      & > .content {
        width: 100%;
        max-width: 382px;
        padding-right: 38px;
        box-sizing: initial;

        @media screen and (max-width: 1000px) {
          padding-right: initial;
          max-width: initial;
        }
      }
    }
  }

  &.gallery-half {
    & > .row {
      justify-content: space-around;

      & > .content {
        width: 100%;
      }

      & > .gallery {
        & > .image {
          & > picture {
            width: auto;
          }

          & > .counter {
            left: 20%;
          }
        }

        @media screen and (max-width: 1000px) {
          width: 100%;

          & > .image {
            height: 626px;
          }
        }
      }
    }
  }

  & > .row {
    flex-direction: row;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    & > .content {
      position: relative;
      z-index: 3;
      background-color: #f9f6ef;
      width: 100%;
      padding-right: 139px;
      box-sizing: border-box;
      &.ebox {
        padding-right: 20px;
        width: unset;
        max-width: 411px;
      }

      @media screen and (max-width: 1000px) {
        padding-right: initial;
        margin-bottom: 0px;
      }

      h2 {
        margin-bottom: 29px;
        color: #372f2f;
      }

      & .gallery {
        display: none !important;

        @media screen and (max-width: 1000px) {
          display: flex;
          margin-bottom: 22px;
          width: 100%;
        }

        & > .marker {
          justify-content: flex-start;

          @media screen and (max-width: 1000px) {
            justify-content: center;
          }
        }
      }

      & > .ab-paragraph-text {
        align-items: flex-start;
        flex: initial;
      }

      & > .ab-link {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      & > a {
        margin-top: 36px;
      }
    }

    & > .gallery {
      @media screen and (max-width: 1000px) {
        display: flex;
        margin-top: 22px;
      }

      & > .marker {
        justify-content: flex-start;
      }
    }

    & > iframe {
      width: 100%;
      height: 450px;
      margin-left: 50px;

      @media screen and (max-width: 1000px) {
        height: 317px;
        margin-left: 0px;
      }
    }

    & > .ab-link {
      display: none;
      margin-top: 40px;

      @media screen and (max-width: 1000px) {
        display: flex;
        align-self: center;
      }
    }
  }
}
