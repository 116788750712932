@import '@styles/_variables.scss';
.ab-form-button {
  margin: 24px 0;
  background: #a79669;
  width: 100%;
  max-width: 277px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: GT Eesti;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  cursor: pointer;
  /* identical to box height, or 160% */

  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  /* BEIGE_ABADÍA */

  color: #f9f6ef;
  transition: background 500ms;

  &:disabled {
    background: #ccc;
  }
}
